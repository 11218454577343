import React from 'react';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

export default function Recommendations(props) {
  const { recommendations } = props;

  if (!recommendations || Object.values(recommendations).length === 0) {
    return null;
  }

  return (
    <div>
      <Alert severity="info">
        Dr. Ber hasn't filled the huddle yet. Here are some insights about he's recent previous
        cases.
        <br />
        Options in <strong>bold</strong> are the defaults he chose.
      </Alert>
      {Object.keys(recommendations).map(fieldId => {
        const opts = recommendations[fieldId].options;
        const a = Object.keys(opts).map((o, index) => {
          if (opts[o].count === 0) {
            return null;
          }
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start'
              }}
            >
              <Typography className={opts[o].inTemplate ? 'font-bold' : ''}>{o}</Typography>
              <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {' '}
                {opts[o].recommendation}
                {index < Object.keys(opts).length - 1 ? ', ' : ''}
              </Typography>
            </div>
          );
        });

        if (a.every(e => e === null)) {
          return null;
        }

        return (
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'start',
              whiteSpace: 'pre-wrap'
            }}
          >
            • {fieldId}:{a}
          </Typography>
        );
      })}
    </div>
  );
}
