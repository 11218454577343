import { gql } from '@apollo/client';

export const PAST_CASE_BASIC_DATA = gql`
  fragment PastCaseBasicData on cases {
    id
    display_id
    site_id
    site {
      id
      name
      attending_only
      specialties
    }
    description
    learned
    resident_role
    feedback
    specialty_id
    specialty_title
    procedure_id
    procedure_title
    created_at
    updated_at
    is_add_on
    state
    case_date
    attending_id
    attending_values
    resident_id
    private_notes {
      note
    }
    patient_gender
    patient_type
    case_state_logs {
      from_state
      to_state
      created_by_id
    }
    attending {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    resident {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    index_in_day
  }
`;

export const PAST_CASE_DATA_FOR_LL = gql`
  fragment PastCaseDataForLL on cases {
    id
    display_id
    site_id
    site {
      id
      name
      attending_only
      specialties
    }
    description
    learned
    resident_role
    feedback
    specialty_id
    specialty_title
    procedure_id
    procedure_title
    created_at
    updated_at
    is_add_on
    state
    case_date
    attending_id
    resident_id
    patient_gender
    patient_type
    case_state_logs {
      from_state
      to_state
      created_by_id
    }
    attending {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    resident {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    private_notes {
      note
    }
    index_in_day
    case_field_values {
      field_id
      values
      other_values
      created_at
      updated_at
    }
  }
`;

export const CASE_BASIC_DATA = gql`
  fragment CaseBasicData on cases {
    id
    display_id
    site_id
    site {
      id
      name
      attending_only
      specialties
    }
    description
    learned
    resident_role
    feedback
    case_state_logs {
      from_state
      to_state
      created_by_id
    }
    case_followers {
      user_id
      last_seen
      user {
        id
        nick_name
        first_name
        last_name
        roles {
          role
        }
        avatar
      }
    }
    specialty_id
    specialty_title
    procedure_id
    procedure_title
    created_at
    updated_at
    is_add_on
    state
    case_date
    attending_id
    attending {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    resident {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    attending_values
    resident_id
    private_notes {
      note
    }
    patient_gender
    patient_type
    case_field_values {
      field_id
      values
      other_values
      created_at
      updated_at
      created_by_id
    }
    plan_feedbacks(order_by: { created_at: asc }) {
      user {
        nick_name
        id
        avatar
        first_name
        last_name
      }
      case_id
      addi_info_selection
      text
      what_went_well_selection
      what_went_well_text
      rating
      how_to_improve_selection
      how_to_improve_text
      text
      id
    }
    index_in_day
    room
  }
`;

export const ADMIN_CASE_BASIC_DATA = gql`
  fragment AdminCaseBasicData on cases {
    id
    display_id
    site_id
    site {
      id
      name
      attending_only
      specialties
    }
    description
    specialty_id
    specialty_title
    procedure_id
    procedure_title
    created_at
    updated_at
    is_add_on
    state
    case_date
    attending_id
    attending_values
    resident_id
    case_state_logs {
      to_state
      created_by_id
    }
    attending {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    resident {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    case_followers {
      user_id
      last_seen
      user {
        id
        nick_name
        first_name
        last_name
        roles {
          role
        }
        avatar
      }
    }
    index_in_day
    room
    is_ready
    standard_procedure
  }
`;

export const CASE_FULL_DATA = gql`
  fragment CaseFullData on cases {
    id
    display_id
    site_id
    site {
      id
      name
      attending_only
      specialties
    }
    procedure_id
    procedure_title
    created_at
    updated_at
    learned
    trackable_goal_result
    specialty_id
    specialty_title
    steps_data
    case_field_values {
      field_id
      values
      other_values
      created_at
      updated_at
      created_by_id
    }
    attending_defaults
    attending_id
    resident_id
    comments(order_by: { created_at: asc }) {
      user {
        nick_name
        id
        avatar
        first_name
        last_name
        roles {
          role
        }
      }
      case_id
      comment
      id
    }
    plan_feedbacks(order_by: { created_at: asc }) {
      user {
        nick_name
        id
        avatar
        first_name
        last_name
      }
      case_id
      addi_info_selection
      text
      what_went_well_selection
      what_went_well_text
      rating
      how_to_improve_selection
      how_to_improve_text
      id
    }
    comment_to_anesthesia
    comment_to_nursing
    state
    case_date
    is_add_on
    description
    goal_id
    goal_name
    goal_other
    result
    result_other
    resident_role
    patient_type
    patient_gender
    intraop_complications
    intraop_complications_details
    summary
    feedback
    attending_values
    procedure_data
    ontology_version
    case_followers {
      user_id
      last_seen
      user {
        id
        nick_name
        first_name
        last_name
        roles {
          role
        }
        avatar
      }
    }
    case_state_logs {
      from_state
      to_state
      created_by_id
      created_at
    }
    plan_usefulness_selection
    plan_usefulness_text
    attending {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    resident {
      id
      nick_name
      pgy
      avatar
      first_name
      last_name
    }
    index_in_day
    draft_resident_id
    draft_resident {
      id
      nick_name
      avatar
    }
    room
  }
`;
