import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import ScheduleCasesList from 'app/components/scheduleCasesList/ScheduleCasesList';
import CasesList from 'app/components/casesList/CasesList';
import CaseDialog from 'app/components/caseViews/CaseDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminDeleteCase,
  deleteCase,
  followCase,
  handleSetOpenCase,
  loadAdminCase,
  loadCase,
  loadNewCases,
  loadNotNewCases,
  resetItems,
  unfollowCase,
  updateCasesState
} from 'app/store/data/casesSlice';
import { optionsSelector } from 'app/metaData/store/enumSlice';
import { CASE_STATE, ROLES } from 'app/consts';

const useStyles = makeStyles(theme => ({
  layoutRoot: {},
  action: {
    alignSelf: 'flex-start'
  }
}));

function SchedulePage(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles(props);
  // eslint-disable-next-line
  const [tab, setTab] = useState('');
  const options = useSelector(optionsSelector);
  const openCase = useSelector(({ data }) => data.cases.openCase);
  const cases = useSelector(({ data }) => data.cases.items);

  const user = useSelector(({ auth }) => auth.user);
  const isUserAdmin = user.role.includes(ROLES.DEPARTMENT_ADMIN);

  const [hideHint, setHideHint] = useState(localStorage.getItem('hide_schedule_plan_hint'));

  const newCases = cases.filter(item => item.state === CASE_STATE.NEW);
  const pastCases = cases.filter(item => item.state !== CASE_STATE.NEW);

  const getCases = useCallback(() => {
    const loadData = async () => {
      await dispatch(loadNewCases(isUserAdmin));
      dispatch(loadNotNewCases(isUserAdmin));
    };

    loadData();
  }, [dispatch]);

  const clearCases = useCallback(() => {
    dispatch(resetItems());
  }, [dispatch]);

  const newCase = () => {
    dispatch(handleSetOpenCase({ case: 'new', editCaseAttributes: true }));
  };

  const loadCaseById = id => {
    dispatch(isUserAdmin ? loadAdminCase(id) : loadCase(id, user.data.id));
  };

  const closeCaseDialog = reload => {
    dispatch(handleSetOpenCase({ case: false, editCaseAttributes: false }));
    if (reload === true) {
      getCases();
    }
  };

  const handleSetCaseFollowers = (caseId, followersToAdd, followersToRemove) => {
    followersToAdd.forEach(f => {
      dispatch(followCase(isUserAdmin, caseId, f.userId, f.lastSeen));
    });
    followersToRemove.forEach(f => {
      dispatch(unfollowCase(isUserAdmin, caseId, f.userId));
    });
  };

  return (
    <FusePageSimple
      classes={{
        root: classes.layoutRoot
      }}
      header={
        <div className="p-24 pl-40">
          <h2>{t('SCHEDULE')}</h2>
        </div>
      }
      content={
        _.isUndefined(cases) ? null : (
          <div className="md:p-24">
            <Switch>
              <Route exact path="/schedule/plan">
                <ScheduleCasesList
                  loadCaseById={loadCaseById}
                  setTab={setTab}
                  newCase={newCase}
                  cases={newCases}
                  handleDelete={async kase => {
                    await dispatch(isUserAdmin ? adminDeleteCase(kase) : deleteCase(kase));
                    getCases();
                  }}
                  handleSubmit={async ids => {
                    await Promise.all(
                      Object.keys(ids).map(state =>
                        dispatch(updateCasesState(isUserAdmin, ids[state], state))
                      )
                    );
                    getCases();
                  }}
                  loadCases={getCases}
                  tab="plan"
                  clearCases={clearCases}
                />
              </Route>
              <Route exact path="/schedule/published">
                <CasesList
                  // cases filtered by status
                  setTab={setTab}
                  cases={pastCases}
                  newCase={newCase}
                  handleDelete={async kase => {
                    await dispatch(isUserAdmin ? adminDeleteCase(kase) : deleteCase(kase));
                    getCases();
                  }}
                  loadCaseById={loadCaseById}
                  loadCases={getCases}
                  tab="published"
                  clearCases={clearCases}
                  groupByDate
                />
              </Route>
            </Switch>
            <CaseDialog
              loadCaseById={loadCaseById}
              closeCaseDialog={closeCaseDialog}
              openCase={openCase}
              options={options}
              adminCase
              handleSetFollowers={handleSetCaseFollowers}
            />
          </div>
        )
      }
    />
  );
}

export default SchedulePage;
