import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import MomentAdapter from '@date-io/moment';
import { getSubSubTitle } from 'app/components/casesList/helper';

const dateLib = new MomentAdapter();

function CasesListItemText({ tab, kase, caseStateLogstoWordle }) {
  const { t } = useTranslation();
  const subSubTitle = getSubSubTitle(tab, kase, t);
  const calculatedDisplayIds = useSelector(({ data }) => data.cases.calculatedDisplayIds);
  const [selectedResidentId, setSelectedResidentId] = useState(kase.draftResidentId);

  useEffect(() => {
    if (selectedResidentId !== kase.draftResidentId) {
      setSelectedResidentId(kase.draftResidentId);
    }
  }, [kase.draftResidentId]);

  const primaryNamesAndRoom = `${kase.attending.nickName}`;

  return (
    <Fragment>
      <ListItemText
        disableTypography
        classes={{ primary: 'capitalize' }}
        primary={
          <div>
            <Typography variant="caption" className="flex flex-row" color="textSecondary">
              {dateLib.date(kase.caseDate).format('l')}
            </Typography>
            <Typography variant="caption" className="flex flex-row" color="textSecondary">
              {kase.site.name}
            </Typography>
            {primaryNamesAndRoom && (
              <Typography
                variant="body"
                className="font-medium flex flex-row"
                style={{ fontWeight: 'bold' }}
              >
                {primaryNamesAndRoom}
              </Typography>
            )}
          </div>
        }
        secondary={
          <div style={{ marginTop: 4, paddingRight: 10 }}>
            <Typography variant="body2" color="textSecondary">
              {caseStateLogstoWordle(kase)}{' '}
              {calculatedDisplayIds[kase.id]
                ? calculatedDisplayIds[kase.id]
                : kase.displayId
                ? kase.displayId
                : kase.procedureTitle}
            </Typography>
            {subSubTitle && (
              <Typography
                variant="body2"
                style={{ fontSize: '90%', fontWeight: 'bold', marginTop: 4 }}
              >
                {subSubTitle}
              </Typography>
            )}
          </div>
        }
      />
    </Fragment>
  );
}

export default CasesListItemText;
