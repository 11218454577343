import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash';
import clsx from 'clsx';
import { deepOrange, grey, lightBlue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import CsvDownloader from 'react-csv-downloader';
import Typography from '@mui/material/Typography';
import { CASE_STATES } from 'app/consts';
import { localISODate } from 'app/pages/cases/helper';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import CasesListItem from './CasesListItem';
import { loadSites } from '../../store/data/adminSlice';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    paddingBottom: 0,
    '&:last-of-type': {
      marginBottom: '5rem'
    }
  },
  groupHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.5rem',
    lineHeight: '2.5',
    fontWeight: 800,
    color: 'black',
    backgroundColor: theme.palette.background.default
  },
  subLabel: {
    backgroundColor: theme.palette.background.default
  },
  avatar: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1.5),
    color: grey[800],
    backgroundColor: lightBlue[50]
  },
  avatarIcon: {
    fontSize: 15
  },
  pre: {
    background: `linear-gradient(to right, ${deepOrange[300]} 25%, ${lightBlue[50]} 25%)`
  },
  post: {
    background: `linear-gradient(to right, ${lightBlue[50]} 75%, ${lightBlue[100]} 75%)`
  },
  dot: {
    alignSelf: 'normal',
    color: theme.palette.secondary.main,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginLeft: '0.5rem'
  },
  fab: {
    position: 'fixed',
    zIndex: 99,
    bottom: theme.spacing(10),
    right: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
}));

export default function CasesList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cases, loadCases, tab, clearCases, setTab } = props;
  const { sites } = useSelector(({ data }) => data.admin);
  const [siteId, setSiteId] = useState('');
  const [filteredCases, setFilteredCases] = useState([]);
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);

  const getSites = useCallback(() => {
    dispatch(loadSites());
  }, [dispatch]);

  useEffect(() => {
    getSites();
  }, [getSites]);

  let grouped;
  let groups;
  if (props.groupByDate) {
    grouped = _.groupBy(filteredCases, c => localISODate(c.caseDate));
    groups = Object.keys(grouped);
  } else {
    grouped = _.groupBy(filteredCases, c => c.state);
    groups = Object.keys(CASE_STATES);
  }

  useEffect(() => {
    setFilteredCases(cases);
  }, [cases]);

  useEffect(() => {
    if (clearCases) clearCases();
    if (loadCases) loadCases();
    if (setTab) setTab(tab);
  }, [loadCases, clearCases, tab, setTab]);

  useEffect(() => {
    if (!siteId) {
      return;
    }
    setFilteredCases(cases.filter(c => c.siteId === siteId));
  }, [cases, siteId]);
  // after cases are loaded, scroll to today's group
  useEffect(() => {
    if (scrolled) {
      return;
    }
    if (!(props.groupByDate && groups.length > 0)) {
      return;
    }
    setScrolled(true);
    const today = new Date();
    const todayString = localISODate(today);
    const todayGroup = document.getElementById(todayString);
    if (todayGroup) {
      todayGroup.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log('today group not found', todayGroup);
    }
  }, [groups]);

  const dayOfWeek = date => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const d = new Date(date);
    return days[d.getDay()];
  };

  const handleClick = async (c, editCaseAttributes) => {
    if (props.loadCaseById) {
      await props.loadCaseById(c.id, editCaseAttributes);
    }
  };

  const handleClickNew = async () => {
    props.newCase();
  };

  const handleDelete = async kase => {
    await props.handleDelete(kase);
  };

  return (
    <Fragment>
      <Grid item sm={12} xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ marginRight: '16px' }}>
          <MuiSelect
            value={siteId}
            onChange={(e, child) => {
              setSiteId(e.target.value);
            }}
            label="site"
          >
            {sites
              .filter(s => s.isActive)
              .map(s => (
                <MenuItem key={s.id} value={s.id} name={s.name}>
                  {s.name}
                </MenuItem>
              ))}
          </MuiSelect>
        </div>
      </Grid>
      {groups.map(
        group =>
          grouped[group] && (
            <List key={group} className={classes.root} subheader={<li />}>
              {CASE_STATES[group] ? (
                <ListSubheader className={classes.groupHeader}>
                  <Avatar
                    title={_.startCase(group)}
                    className={clsx(
                      classes.avatar,
                      CASE_STATES[group].color && classes[CASE_STATES[group].color]
                    )}
                  >
                    <Icon className={classes.avatarIcon}>{CASE_STATES[group].icon}</Icon>
                  </Avatar>
                  {t(CASE_STATES[group].label)}
                  {props.handleExport && (
                    <CsvDownloader
                      className="ml-auto"
                      datas={props.handleExport}
                      filename="cases-log"
                    >
                      <Button
                        title={t('Export')}
                        className="ml-auto h-24"
                        size="small"
                        variant="outlined"
                      >
                        {t('Export')}
                      </Button>
                    </CsvDownloader>
                  )}
                </ListSubheader>
              ) : (
                <ListSubheader className={classes.groupHeader} id={group}>{`${group}, ${dayOfWeek(
                  group
                )}`}</ListSubheader>
              )}
              {CASE_STATES[group]?.subLabel && (
                <Typography
                  className={clsx('text-xs pl-20 pb-4', classes.subLabel)}
                >{`\n${CASE_STATES[group].subLabel}`}</Typography>
              )}
              {grouped[group].map((c, index) => (
                <CasesListItem
                  kase={c}
                  key={c.id}
                  handleClick={handleClick}
                  handleDelete={handleDelete}
                  handleArchive={props.handleArchive}
                  handleShare={props.handleShare}
                  indexInList={index}
                />
              ))}
            </List>
          )
      )}

      {props.newCase && (
        <Fab onClick={() => handleClickNew()} className={classes.fab} aria-label="add">
          <Icon>add</Icon>
        </Fab>
      )}
    </Fragment>
  );
}
