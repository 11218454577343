import _ from '@lodash';
import apiService from 'app/services/apiService';
import auth0Service from 'app/services/auth0Service';

export const SUGGEST_MIN_CASES_FOR_THRESHOLD = 10;
const SUGGEST_MIN_CASES_FOR_STREAK = 3;
const SUGGEST_RATIO_THRESHOLD = 0.7; // Use 0.1 for debug

// Merge procedureSubTypeFieldOptions for current cliendId with default ones
const initPstfo = pstfo => {
  const pstfoDefault = pstfo.filter(fo => !fo.clientId || fo.clientId === 'default');
  const pstfoClientId = pstfo.filter(fo => fo.clientId === auth0Service.audPrefix);

  // remove items that are in pstfoClientId from pstfoDefault
  const pstfoDiff = _.differenceWith(
    pstfoDefault,
    pstfoClientId,
    (a, b) => a.fieldId === b.fieldId
  );

  return [...pstfoDiff, ...pstfoClientId];
};

const calcCaseRecommendation = (fieldCount, count, inTemplate) => {
  const ratio = (count * 100) / fieldCount;
  const isRecommended = ratio >= SUGGEST_RATIO_THRESHOLD;

  // return `${inTemplate ? 'In template, ' : ''}${count}/${fieldCount}, ${ratio.toFixed(0)}%`;
  // return `${count}/${fieldCount}, ${ratio.toFixed(0)}%`;
  return `(${ratio.toFixed(0)}%)`;
};

// Passing fieldsSummaryLocal as parameter because setFieldsSummary is async
const initCaseRecommendations = (kase, fieldsSummaryLocal) => {
  const res = {};

  console.debug('initCaseRecommendations');
  console.debug('attendingDefaults', kase.attendingDefaults);

  const pstfo = initPstfo(kase.procedureData.subType.procedureSubTypeFieldOptions);
  console.debug('fieldsSummaryLocal', fieldsSummaryLocal);
  console.debug('pstfo', pstfo);

  pstfo.forEach(fo => {
    const fs = fieldsSummaryLocal[fo.fieldId];
    if (!fs) {
      return;
    }

    res[fo.fieldId] = res[fo.fieldId] || {
      options: {},
      count: fs.count
    };

    fo.options.forEach(opt => {
      const inTemplate = !!kase.attendingDefaults?.values?.[fo.fieldId]?.values.includes(opt);
      const count = fs.options[opt] || 0;
      const caseRecommendation = calcCaseRecommendation(fs.count, count, inTemplate);
      const add = shouldRecommendTemplateFieldValAdd(
        fieldsSummaryLocal,
        kase.procedureTitle,
        fo.fieldId,
        opt
      );
      const remove = shouldRecommendTemplateFieldValRemove(
        fieldsSummaryLocal,
        kase.procedureTitle,
        fo.fieldId,
        opt
      );

      const attRecommendation = add && !inTemplate ? '+' : remove && inTemplate ? '-' : '';

      res[fo.fieldId].options[opt] = res[fo.fieldId].options[opt] || {
        count,
        inTemplate,
        caseRecommendation,
        attRecommendation
      };
    });
  });

  return res;
};

export const initTemplateRecommendations = async (userId, kase, onCaseRecommendations) => {
  const fs = {};

  // Go over past cases, and create a summary: count of each field, and count of each option
  const cases = await apiService.getAttReadyCasesForTemplateRecommendations(
    userId,
    kase.procedureId,
    SUGGEST_MIN_CASES_FOR_THRESHOLD
  );

  cases.forEach(pc => {
    pc.caseFieldValues.forEach(f => {
      fs[f.fieldId] = fs[f.fieldId] || {
        count: 0,
        options: {}
      };

      fs[f.fieldId].count += 1;

      f.values.forEach(v => {
        fs[f.fieldId].options[v] = (fs[f.fieldId].options[v] || 0) + 1;
      });
    });
  });

  if (onCaseRecommendations) {
    const rec = initCaseRecommendations(kase, fs);
    onCaseRecommendations(rec);
  }

  return fs;
};

const shouldRecommendTemplateFieldValAdd = (
  fieldsSummary,
  procedureTitle,
  fieldId,
  value,
  multi
) => {
  const count = fieldsSummary[fieldId]?.options[value] || 0;
  const total = fieldsSummary[fieldId]?.count || 0;
  // console.debug('fieldId:', fieldId, count, '/', total);

  const ratio = count / total;
  if (
    (ratio >= SUGGEST_RATIO_THRESHOLD && total >= SUGGEST_MIN_CASES_FOR_THRESHOLD) ||
    (count === total &&
      total >= SUGGEST_MIN_CASES_FOR_STREAK &&
      total < SUGGEST_MIN_CASES_FOR_THRESHOLD)
  ) {
    return {
      procedureTitle,
      fieldId,
      value,
      isAdd: true,
      multi
    };
  }

  return false;
};

const shouldRecommendTemplateFieldValRemove = (
  fieldsSummary,
  procedureTitle,
  fieldId,
  value,
  multi
) => {
  const count = fieldsSummary[fieldId]?.options[value] || 0;
  const total = fieldsSummary[fieldId]?.count || 0;
  // console.debug('fieldId:', fieldId, count, '/', total);

  const ratio = count / total;
  if (
    (ratio <= 1 - SUGGEST_RATIO_THRESHOLD && total >= SUGGEST_MIN_CASES_FOR_THRESHOLD) ||
    (count === 0 &&
      total >= SUGGEST_MIN_CASES_FOR_STREAK &&
      total < SUGGEST_MIN_CASES_FOR_THRESHOLD)
  ) {
    return {
      procedureTitle,
      fieldId,
      value,
      isAdd: false,
      multi
    };
  }

  return false;
};

const recommendTemplateCheckSkip = (attendingDefaults, fieldId, value) => {
  if (value === 'Other') {
    console.debug('added other');
    return false;
  }
  console.debug('att defaults', attendingDefaults);
  if (!attendingDefaults || Object.keys(attendingDefaults).length === 0) {
    console.debug('No attending defaults');
    return false;
  }
  if (attendingDefaults.neverSaveDefaults) {
    console.debug('never save defaults');
    return false;
  }

  if (attendingDefaults.stop_insights?.[fieldId]?.includes(value)) {
    console.debug('Stop insights', value);
    return false;
  }

  return true;
};

export const recommendTemplateAdd = (
  attendingDefaults,
  fieldsSummary,
  procedureTitle,
  fieldId,
  values,
  oldValue,
  multi
) => {
  let addedValue;
  if (Array.isArray(values)) {
    // remove 'values' from 'oldValue'
    const addedArr = _.difference(values, oldValue);
    console.debug('added', addedArr);
    if (addedArr.length !== 1) {
      // unexpected
      console.debug('addedArr.length != 1');
      return false;
    }

    addedValue = addedArr[0].value;
  } else {
    addedValue = values.value;
  }

  if (!recommendTemplateCheckSkip(attendingDefaults, fieldId, addedValue)) {
    return false;
  }

  if (attendingDefaults.values[fieldId]?.values.includes(addedValue)) {
    console.debug('Already in template', addedValue);
    return false;
  }

  return shouldRecommendTemplateFieldValAdd(
    fieldsSummary,
    procedureTitle,
    fieldId,
    addedValue,
    multi
  );
};

export const recommendTemplateRemove = (
  attendingDefaults,
  fieldsSummary,
  procedureTitle,
  fieldId,
  values,
  oldValue,
  multi
) => {
  let removedValue;
  if (Array.isArray(values)) {
    // remove 'values' from 'oldValue'
    const removedArr = _.difference(oldValue, values);
    console.debug('removed', removedArr);
    if (removedArr.length !== 1) {
      // unexpected
      console.debug('removedArr.length != 1');
      return false;
    }

    removedValue = removedArr[0].value;
  } else {
    removedValue = oldValue.value;
  }

  if (!recommendTemplateCheckSkip(attendingDefaults, fieldId, removedValue)) {
    return false;
  }

  if (!attendingDefaults.values[fieldId]?.values.includes(removedValue)) {
    console.debug('Not in template', removedValue);
    return false;
  }

  return shouldRecommendTemplateFieldValRemove(
    fieldsSummary,
    procedureTitle,
    fieldId,
    removedValue,
    multi
  );
};
